<template>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
            <van-image :src="require('../assets/images/product03.png')" />
        </van-swipe-item>
        <van-swipe-item>
            <van-image :src="require('../assets/images/product04.png')" />
        </van-swipe-item>

    </van-swipe>


    <article class="goodslist">
        <a class="goodsDetail" :href="'/TelecomBroadbandIndex/hangzhou/'+source">
            <img src="../assets/images/hzdx1.png" style="width: 2rem;">
            <div class="goodsText">
                <h3>中国电信杭州地区宽带</h3>
                <span>
                    单宽带包年、包月、融合宽带业务办理。
                </span>
                <span>
                    价格：60~1000元
                </span>
            </div>
        </a>

        <a class="goodsDetail" :href="'/unicornBroadBand/hangzhou/'+source">
            <img src="../assets/images/hzlt1.png" style="width: 2rem;">
            <div class="goodsText">
                <h3>中国联通杭州地区宽带</h3>
                <span>
                    联通200M宽带，包年仅需360元
                </span>
                <span>
                    价格：58~1200元
                </span>
            </div>
        </a>

    </article>

    <footer class="footer">

        <a>版权所有：杭州我爱号码网电子商务有限公司</a>
        <a>公司地址：浙江省杭州市拱墅区兴业街16号物联网产业园6幢422室</a>
        <a>联系电话：0571-28888520</a>

<!--        <a target="_blank" href="http://www.beian.gov.cn" >-->
<!--            <img src="./assets/gaba.png" />-->
<!--            <span >浙公网安备 33010502006969号</span >-->
<!--        </a>-->
        <a href="https://beian.miit.gov.cn" target="_blank" >浙ICP备19034816号-3</a>

    </footer>



</template>

<script>
    export default {
        name: "MainIndex",
        data(){
            return {
                source: this.$route.params.source==null?"52haoma":this.$route.params.source
            }
        },

        methods:{
            toUnicornBroadBand(){
                this.$router.push({
                    path:"/unicornBroadBand/hangzhou/null"
                })
            },
            toTelecomBroadband(){
                this.$router.push({
                    path:"/TelecomBroadbandIndex/hangzhou/null"
                })
            }
        }
    }
</script>

<style scoped>
    .my-swipe{
        display: block;
        width: 100%;
        height: 3.5rem;
    }
    .goodslist{
        width: 7.4rem;
        background: aliceblue;
        padding: 0.5rem 0.05rem;

    }
    .goodsDetail{
        display: flex;
        height: 2rem;
        background: #FFFFFF;

        cursor: pointer;
        margin-bottom: 0.2rem;


    }
    .goodsText{
        width: 5rem;
        margin-left: 0.3rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
    .goodsText h3{
        font-size: 0.3rem;
    }
    .goodsText span{
        font-size: 0.28rem;
    }
    .footer{
        margin-top: 2rem;
        width: 7.5rem;
        height: 1.8rem;
        text-align: center;
        bottom: 0.1rem;
        font-size: 0.25rem;
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;

    }
    .footer a{
        line-height: 0.35rem;
    }
</style>