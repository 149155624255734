import { createRouter, createWebHistory } from "vue-router";
import MainIndex from "views/MainIndex";
import UnicornBroadBand from "@/views/UnicornBroadBand";
import TelecomBroadbandIndex from  "views/TelecomBroadbandIndex";
const routes = [
    {
        path: "/",
        component:MainIndex,
        meta:{
            title: '我爱宽带网'
        }
    },
    {
        path: "/:source",
        component:MainIndex,
        meta:{
            title: '我爱宽带网'
        }
    },
    {
        path: "/unicornBroadBand/hangzhou/:source",
        component:UnicornBroadBand,
        meta:{
            title: '中国联通'
        }
    },
    {
        path: "/TelecomBroadbandIndex/hangzhou/:source",
        component:TelecomBroadbandIndex,
        meta:{
            title: '中国电信'
        }
    }
];

const router = createRouter({
    history: createWebHistory('/broad_band'),
    routes
});

export default router;
