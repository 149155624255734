<template>
    <van-image class="van-image" :src="require('../assets/images/lt01.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt02.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt03.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt04.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt05.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt06.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt07.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt08.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('../assets/images/lt09.gif')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-notice-bar left-icon="volume-o" background="#ffffff" >当前已有{{reservationNumber}}人预约</van-notice-bar>
    <van-form @submit="onSubmit" ref="form">
        <van-cell-group inset>
            <van-field
                    v-model="optObj.username"
                    name="办理人姓名"
                    label="办理人姓名"
                    placeholder="办理人姓名"
                    required
                    :rules="[{ required: true, message: '请填写办理人姓名' }]"
            />
            <van-field
                    v-model="optObj.phoneNumber"
                    type="tel"
                    name="联系电话"
                    label="联系电话"
                    placeholder="联系电话"
                    required
                    :rules="[{ required: true, message: '请填写联系电话' },{ pattern:phoneNumberPattern, message: '手机号码不合法'}]"
            />
            <van-field
                    v-model="optObj.idCard"
                    name="身份证号"
                    label="身份证号"
                    placeholder="身份证号"
                    required
                    :rules="[{ required: true, message: '请填写身份证号'},{ pattern:idCardPattern, message: '身份证号不合法'}]"
            />
            <van-field
                    v-model="productText"
                    is-link
                    readonly
                    required
                    name="套餐选择"
                    label="套餐选择"
                    placeholder="套餐选择"
                    :rules="[{ required: true, message: '请填写套餐选择' }]"
                    @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" position="bottom">
                <van-picker
                        :columns="columns"
                        @confirm="onProductConfirm"
                        @cancel="showPicker = false"
                />
            </van-popup>
            <van-field
                    v-model="addressAll"
                    is-link
                    readonly
                    required
                    name="安装地址"
                    label="安装地址"
                    placeholder="点击选择省市"
                    @click="showArea = true"
                    :rules="[{ required: true, message: '请填写安装地址' }]"
            />
            <van-popup v-model:show="showArea" position="bottom">
                <van-area
                        :area-list="areaList"
                        @confirm="onAreaConfirm"
                        @cancel="showArea = false"
                />
            </van-popup>
            <van-field
                    v-model="optObj.address"
                    name="详细地址"
                    label="详细地址"
                    placeholder="详细地址"
                    v-show="showAddress"
                    required
                    :rules="[{ required: true, message: '请填写详细地址' }]"
            />
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block  type="primary" native-type="submit">
                免费预约
            </van-button>
        </div>
    </van-form>
    <div class="content-layout">
        <div class="liucheng">
            <van-image class="van-image prompt" :src="require('../assets/images/liantong_liucheng.png')" lazy-load width="4rem" fit="contain" >
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error>加载失败</template>
            </van-image>
            <van-image class="van-image step" :src="require('../assets/images/liantong_liucheng_step.png')" lazy-load width="6rem" fit="contain" >
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error>加载失败</template>
            </van-image>
        </div>
    </div>

    <h3>宽带办理须知</h3>
    <div class="introduce">
        <p>
            <span class = "flag">1、实名要求:</span>宽带新装及续费需实名，请在订单提交页面<span class="color-flag">正确填写与宽带绑定的姓名、身份证号码</span>。新装上门安装时需核对用户信息<br/>
            <span class = "flag">2、安装区域:</span>本套餐仅限浙江省已接入联通自有光宽带资源的小区办理，目只适合家庭和个人用户办理，仅可安装在住宇小区、商务楼宇、工业园区及校园等区域集团客户暂不支持安装。<br/>
            <span class = "flag">3、安装地址:</span>您的安装地址默认为收货地址。因需验证您所在地是否有宽带资源票盖。<span class="color-flag">请将您的收传地址精确到x小区x幢x门牌号</span>。如有特殊情况需要修改、请联系客服修改收货地址。<br/>
            <span class = "flag">4、设备归属:</span>光猫(机顶)免费租用。购买需承诺在网一年内不得提前拆机(退网)，如协议期内违约需补收设备租用费100元/台;光猫(机顶盒)归还浙江联通所有，用户拆机时需同步携带设备(如光猫、机顶盒)前往营业厅进行拆机，拆机时若设备遗失需缴纳设备费100元台。用户在网2年后，设备归用户所有。<br/>
            <span class = "flag">5、宽带速率说明:</span>公众宽带产品的接入速率均指下行速率。100--1000M宽带若无其他说明，则上行速率一般为40M，宽带速率受网线、路由设备电脑网卡，终端设备等因素影响，可能不能完全达到其理论下行速率。<br/>
            <span class = "flag">6、新装缓装说明:</span>宽带需在30天内预约安装，若超期后仍未安装成功，联通将有权取消订单，取消后已支付费用将在7-15个工作日内原路退回。<br/>
            <span class = "flag">7、高速率宽带测速问题:</span>200M以上网速需要干兆光猫，千兆路由，干非网线才能体验到全部网速哦。<br/>
            ①连接路由器的网线题插在光猫干兆口②网线要超五类网线以上(网线侧面有cat5e或cat6)③路由器建议千兆双频路由<br/>
            <span class = color-flag>若订购200M以上网速，测网速只有百非，请先一-确认设备，除光猫外需要您自行解决哦</span>。<br/>
            <span class = "flag">8.续费补充说明:</span>①原资费续费可提前3个目内办理。②<span class="color-flag">换速宝续费时</span>，您需与客服联系核实您的线路资源是否支持对应网速。仅可提前7天办理，办理成功后，原资费到期后将更换为续费资费，如您购买则默认认可更换宽带资费。③续费非自动办理，店铺将在您订购后0-3个工作日内完成办理。<br/>
            <span class = "flag">9、沃家电视业务:</span>新一代交互式宽带视频服务，包括直播、点播、回看节目、游戏、教育等各种应用。内容涵盖超80路高清直播电视(含中央3套、5套、6套、8套)及电影、电视剧、少儿、综艺等多个栏目内容120元一年，到期后，可在店铺/营业厅充值续费。<br/>
            <span class = "flag">10、部分无法受理说明:</span>宽带业务为特殊产品，若拍下后不符合办理条件，我们将联系您退款<br/>
            ①若为联通里名单用户，则无法办理联通案带新装及结费<br/>
            ②若为欠费类黑名单，可联系店辅客服，<span class="color-flag">在店铺到费解除里名单后办环业务</span>。<br/>
            ③新装及续费，即可受理联通自有宽带资源，其他合作宽带无法在店办理。<br/>
        </p>
    </div>

</template>

<script>
    import { ref , reactive , watch } from 'vue';
    import {areaList} from "@/assets/js/areaList";
    import { Dialog } from 'vant';
    export default {
        name: "UnicornBroadBand",
        setup() {
            //预约人数
            const reservationNumber = ref(0)
            //详细地址显示开关
            const showAddress = ref(false)
            //身份证号正则表达式
            const idCardPattern = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            //电话号码正则表达式
            const phoneNumberPattern = /^[1][3,4,5,6,7,8,9][0-9]\d{8}$/;
            //表单操作对象
            const optObj = reactive({
                username:"",
                phoneNumber:"",
                idCard:"",
                productId:"",//套餐id
                address:"",//详细地址
                province:"",  //省
                city:"",//市
                distrct:"",//县
                commodityId:"",//商品id
                optId:2,//运营商
                agentId:""//代理商id
            });
            //套餐选择
            const productText = ref('');
            const showPicker = ref(false);
            const columns = [{id:1,text:"单宽200M 360元/年"},{id:2,text:"单宽300M 480元/年"},{id:3,text:"单宽500M 800元/年"},{id:4,text:"单宽1000M 1200元/年"},{id:5,text:"融合200M 58/月"},{id:6,text:"融合500M 129/月"}];
            const onProductConfirm = (value) => {
                productText.value = value.text;
                optObj.productId = value.id;
                showPicker.value = false;
            };

            //安装地址
            const showArea = ref(false);
            const onAreaConfirm = (areaValues) => {
                showArea.value = false;
                addressAll.value = areaValues
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
            };
            //省市区
            const addressAll = ref('')
            watch(addressAll,(newValue)=>{
                if (newValue == null || newValue == ''){
                    return
                }
                const addressList = newValue.split("/")
                optObj.province = addressList[0]
                optObj.city = addressList[1]
                optObj.distrct = addressList[2]
                showAddress.value = true
            });
            //setup返回对象
            return {
                reservationNumber,
                optObj,
                showPicker,//选择器显示控制开关
                columns,//选择的套餐数据
                onProductConfirm,//选中回调函数
                showArea,
                onAreaConfirm,
                areaList,//地址列表
                addressAll,
                showAddress,//详细地址显示开关
                productText,
                idCardPattern,
                phoneNumberPattern
            };
        },
        methods:{
            onSubmit(){
                this.$axios.post("/manager/band/broadBand/broadBand-insert",this.optObj).then((resp)=>{
                   if(resp.data.status == this.STATUS_SUCCESS){
                       Object.keys(this.optObj).forEach(key =>{
                           if(key == 'optId'){
                               this.optObj[key] = 2
                           }else {
                               this.optObj[key] = ''
                           }
                       })
                       this.addressAll = ''
                       this.productText = ''
                       Dialog.alert({
                           message: '预约成功，请保持电话畅通等待工作人员联系。',
                       }).then(() => {
                       });
                   }else if(resp.data.status == this.FAIL){
                        Dialog.alert({
                            message: '已预约成功，请勿重复预约。',
                        }).then(() => {
                        });
                    }
                })
            }
        },
        mounted() {
            this.$axios.post("/manager/band/broadBand/broadBand-count").then((resp)=>{
                this.reservationNumber = resp.data.data;
            })
            this.optObj.agentId =  this.$route.params.source
        }
    }
</script>

<style scoped>
    .van-image {
        display: block;
    }
    .content-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content-layout .liucheng {
        background-color: #FFFFFF;
        width: 90%;
        margin-top: .45rem;
        padding: .267rem 0 .8rem;
        display: flex;
        flex-direction: column;
        font-size: .35rem;
        color: #333333;
        font-weight: 400;
    }
    .content-layout .liucheng .prompt {
        width: 5.34rem;
        height: auto;
        margin: 0 auto .64rem;
    }
    .content-layout .liucheng .step {
        height: auto;
        margin: 0 auto;
    }
    h3{
        display: block;
        width: 2rem;
        height: 0.4rem;
        margin: 0.1rem auto 0.2rem 0.2rem;
        font-size: 0.28rem;
        background:#f26522;
        color: white;
        text-align:center;
        border-radius:0.1rem ;
    }
    .introduce{
        width: 6.8rem;
        height: auto;
        padding: 0 0.3rem;
        margin-bottom: 0.4rem;
        font-size: 0.16rem;
        line-height: 0.4rem;
    }
    .flag{
        font-weight:bold;
    }
    .color-flag{
        color: #f36c21;
    }
</style>
