import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import axios from 'axios'
import  {Search, Divider ,Tab, Tabs, Button, Image, Col, Row , Lazyload, NoticeBar, Form, Field, CellGroup, Picker, Popup, Loading, Area, Swipe, SwipeItem, Card , Cell ,Dialog} from 'vant';
import VueWechatTitle from 'vue-wechat-title'

var app = createApp(App);
app.use(router)
app.use(Button).use(Image).use(Col).use(Row).use(Lazyload)
    .use(NoticeBar).use(Form).use(Field).use(CellGroup).use(Picker)
    .use(Popup).use(Loading).use(Area).use(Swipe).use(SwipeItem).use(Card)
    .use(Cell).use(Dialog).use(Tabs).use(Tab).use(Divider).use(Search )
app.use(VueWechatTitle)

app.mount('#app')
app.config.globalProperties.$axios = axios;
app.config.globalProperties.STATUS_SUCCESS ="0000";
app.config.globalProperties.FAIL ="1111";

