<template>

  <p class="changeP" >
    <a href="#table">免费预约办理</a>
  </p>

    <van-image class="van-image" :src="require('assets/images/broad_band03.png')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('assets/images/telecomBanner (2).jpg')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image" :src="require('assets/images/broad_band04.png')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
  <!--  <van-image class="van-image" :src="require('assets/images/telecomBanner (4).jpg')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>
    <van-image class="van-image"  :src="require('assets/images/telecomBanner(5).jpg')" lazy-load width="7.5rem" fit="contain" >
        <template v-slot:loading>
            <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>加载失败</template>
    </van-image>-->
        <!--预约人数-->
        <van-notice-bar id="count" left-icon="volume-o" background="#ffffff" >当前已有{{count}}人预约</van-notice-bar>

    <article id="table" class="phone_number_art">
        <div class="phone_number_div">
                <div class="phone_number_query">
                        <span :class="types=='杭州市'?'selecteds':''"  @click="selectCityProduct('杭州市')"> 杭州市</span>
                        <span :class="types=='宁波市'?'selecteds':''"  @click="selectCityProduct('宁波市')"> 宁波市</span>
                        <span :class="types=='衢州市'?'selecteds':''"  @click="selectCityProduct('衢州市')"> 衢州市</span>
                        <span :class="types=='舟山市'?'selecteds':''"  @click="selectCityProduct('舟山市')"> 舟山市</span>
                        <span :class="types=='金华市'?'selecteds':''"  @click="selectCityProduct('金华市')"> 金华市</span>
                        <span :class="types=='丽水市'?'selecteds':''"  @click="selectCityProduct('丽水市')"> 丽水市</span>
                        <span :class="types=='温州市'?'selecteds':''"  @click="selectCityProduct('温州市')"> 温州市</span>
                        <span :class="types=='台州市'?'selecteds':''"  @click="selectCityProduct('台州市')"> 台州市</span>
                        <span :class="types=='绍兴市'?'selecteds':''"  @click="selectCityProduct('绍兴市')"> 绍兴市</span>
                        <span :class="types=='嘉兴市'?'selecteds':''"  @click="selectCityProduct('嘉兴市')"> 嘉兴市</span>
                        <span :class="types=='湖州市'?'selecteds':''"  @click="selectCityProduct('湖州市')"> 湖州市</span>
            
                </div>
        
                <div  v-if="productParticulars.endTime !=null || productParticulars.endTime != ''" class="menu_detail_div">
                  
                    <table  border="1px solid #ccc" cellspacing="0" bordercolor="#6699FF" cellpadding="0" class="label_div">
                        <tr>
                            <th style="color:#6699FF; font-size: 16px; font-weight: bold;">套餐信息</th>
                            <th style="color:#6699FF; font-size: 16px; font-weight: bold;">价格</th>
                            <th style="color:#6699FF; font-size: 16px; font-weight: bold;">结束时间</th>
                            <th style="color:#6699FF; font-size: 16px; font-weight: bold;">立即下单</th>
                        </tr>
                        <tbody>
                            <tr  v-for="item in productParticulars" :key="item.id"  @click="propsView(item)">
                                <td>{{item.name}}</td>
                                <td style="color: red; font-size: 16px; font-weight: bold">{{item.price}}元</td>
                                <td>{{item.endTime}}</td>
                                <td><van-button  size="mini" type="primary">立即下单</van-button></td>
                            </tr>
                            <tr>
                                <td style="color: #6699FF; font-size: 16px; font-weight: bold">备注</td>
                                <td colspan="3">
                                    <table  class="table" >
                                        <tr v-for="(item) in productParticulars" :key="item.id">
                                            <td style="width: 5.1rem">{{item.name}}{{item.remark}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
         </div>
        
                    
    </article>

        <!--表单开始-->

    <van-popup style="width: 7rem;" v-model:show="productView" round>
        <van-form @submit="onSubmit">
            <van-cell-group inset>
                <van-field
                        v-model="optObj.username"
                        required
                        name="办理人姓名"
                        label="办理人姓名"
                        placeholder="办理人姓名"
                        :rules="[{ required: true, message: '请填写办理人姓名' }]">
                </van-field>
                <van-field
                        v-model="optObj.phoneNumber"
                        required
                        type="tel"
                        name="联系电话"
                        label="联系电话"
                        placeholder="联系电话"
                        :rules="[{ required: true, message: '请填写联系电话' ,validator: phoneValidator }]">
                </van-field>
                <van-field
                        v-model="optObj.idCard"
                        required
                        name="身份证号码"
                        label="身份证号码"
                        placeholder="身份证号码"
                        :rules="[{ required: true, message: '请填写身份证号码' , validator: idCardValidator}]">
                </van-field>

                <!--地址选择-->
                <van-field v-model="selectAddress"
                           is-link
                           readonly
                           required
                           name="安装地址"
                           label="安装地址"
                           placeholder="点击选择省市"
                           @click="showArea = true"/>
                <!--弹出层-->
                <van-popup v-model:show="showArea" position="bottom" :style="{ height: '50%' }" >
                    <van-area title="" :columns-num="3" @cancel="showArea = false"   @confirm="onAreaConfirm"
                              :area-list="areaList" >
                    </van-area>
                </van-popup>
                <van-field
                        v-model="optObj.address"
                        v-show="Detailed"
                        required
                        name="详细地址"
                        label="详细地址"
                        placeholder="详细地址"
                        :rules="[{ required: true, message: '请填写详细地址' }]">
                </van-field>

                <!--套餐选择-->
                <van-field
                        v-model="optObj.name"
                        required
                        name="套餐"
                        label="套餐"
                        placeholder="套餐">
                </van-field>
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    免费预约
                </van-button>
            </div>
        </van-form>



    </van-popup>   
    
        <!--表单结束-->

        <!--安装流程-->
        <van-image class="van-image"  none  :src="require('assets/images/dx16.jpg')" lazy-load  width="7.5rem" style="margin: 0 auto;">
            <template v-slot:loading>
                <van-loading type="spinner" size="20" />
            </template>
            <template v-slot:error>加载失败</template>
        </van-image>

        <h3>宽带办理须知</h3>

        <div class="introduce">
            <p>
                1、新装宽带用户需收安装调测费，具体以价格表为准。宽带含电信电视1部(自愿选装)，具体以电信电视价格表为准。<br/>
                2、用户办理以上包年宽带，请将您的姓名、身份证号、联系电话，安装地址留言备注。<br/>
                3、安装时，请提前准备好办理宽带用户的身份证原件装维师傅上门安装时需要核对用户信息。<br/>
                4、宽带及电信电视到期后自动转包月并电信月标准资费收取。(单宽到期费:50M/100M是120元/月，200M是160元/月，300M是190元/月，500M是290元/月，1000M是390元/月:电信电视到期资费10元/月)纯宽带包年用户中途离网，属于违约行为，需按宽带包月的标准资费进行计算，剩余费用做退还，如按标准资费计算已超过包年费用，则不需要用户进行补差，无费用进行退还宽带安装调测费为一次性费用，不退还。宽带到期欠费未缴纳，会产生滞纳金，具体以电信政策为准。<br/>
                5、宽带100M2年到期后恢复50M速率、200M包2年到期后恢复100M速率、300M包2年到期后恢复200M速率、500M包2年到期后恢复200M速率、1000M包2年到期后恢复500M速率。<br/>
                6、宽带续费规则:宽带续费由于宽带速率无法衔接时间问题，需已有宽带包年2个月内(含2个月)到期用户才可续费，已有宽带包年2个月以上到期，请在到期后进行续费。单宽续费用户下单前，请联系客服确认。<br/>
                7、本产品为单宽新装，暂不支持融合套餐续费，如强制续费则号卡与宽带分离单独计费。线上不支持融合套餐变更变改须到电信营业厅办理。<br/>
                8、电信宽带业务为特殊产品，若拍下后不符合办理条件，我们将联系您退款。<br/>
                9、活动期间不可缓装。若有价格差异变动，所造成的差价将不予退还，敬请谅解。
            </p>
        </div>

        


    
</template>


<script>
    import {areaList} from "@/assets/js/areaList";
    import { ref , reactive , watch } from 'vue';
    import { Dialog } from 'vant';
    import isIdcard from "../assets/js/idcard";

    export default {
        name: "TelecomBroadbandIndex",
        data() {
            return {
                productList:null,
                hzProductList:null,
            }
        },
            setup() {
                const optObj = reactive({
                    commodityId:"",
                    optId:3,
                    username:"",        //姓名
                    phoneNumber:"",     //手机号
                    idCard:"",          //身份证号码
                    productId:"",     //套餐选择
                    address:"" ,      //详细地址
                    province:"",  //省
                    city:"",      //市  
                    distrct:"",    //县
                    agentId:"",  //代理商id
                    name:""   //台词名字
                });
               
                const Detailed =ref(false)
                const types = ref('杭州市');
                
                const  productParticulars =reactive({
                    name:"",
                    price:"",
                    remark:"",
                    endTime:"",
                    province:"",
                    city:""
                })
                const productView = ref(false);
                const propsView = (value) =>{
                    
               
                    productView.value = true;
                    optObj.name = value.name,
                    optObj.productId = value.id
                    console.log(  this.optObj.name);
                    console.log(this.optObj.id);
                    this.$forceUpdate();
                }
                
                //地址选择
                const selectAddress = ref('')
                    watch(selectAddress,(newValue)=>{
                        const adresList = newValue.split("/");
                        optObj.province=adresList[0];
                        optObj.city=adresList[1];
                        optObj.distrct=adresList[2];
                        Detailed.value =true;
                    });

                //省市区
                const showArea = ref(false)
                const onAreaConfirm = (areaValues) => {
                    productText.value = '';
                    showArea.value = false;
                    selectAddress.value = areaValues
                        .filter((item) => !!item)
                        .map((item) => item.name)
                        .join('/');
                };


                //套餐选择
                const broadBandProduct = [];
                const productText = ref('');
                const columns = [];
                const hzColumns=[];
                const showPicker = ref(false);
                // const onConfirm = (value) => {
                //     let pList = this.productList;
                //     if (pList!=null && pList.length!=0){
                //         for (let i=0;i<pList.length;i++){
                //             let productDetail = pList[i];
                //             if (productDetail.name == value){
                //                 optObj.productId = productDetail.id;
                //                 break;
                //             }
                //         }
                //         productText.value=value;
                //         this.showPicker = false;
                //     }
                //    
                // };

              
                
                const count =ref(0)
                return {
                    broadBandProduct,
                    columns,  //非套餐
                    hzColumns, //杭州套餐
                    // onConfirm,
                    showPicker, //套餐弹窗开关
                    optObj,   //表单数据
                    areaList,  //省市县
                    showArea,   //省市区弹窗
                    onAreaConfirm,
                    selectAddress, //保存省市县
                    Detailed , //详细地址
                    productText,  //套餐值
                    count,  //下单总人数
                    types,  //选择器
                    productParticulars, //套餐详情
                    productView, //套餐弹窗
                    propsView,
                };
            },
            methods:{
                //宽带下单回传id
                onConfirm(value){
                    let pList =[];
                    
                    if (this.optObj.city == "杭州市"){
                        pList = this.hzProductList
                    }else {
                        pList = this.productList
                    }
                    if (pList!=null && pList.length!=0){
                        for (let i=0;i<pList.length;i++){
                            let productDetail = pList[i];
                            
                            if (productDetail.name == value){
                               this.showPicker = false;
                               this.optObj.productId = productDetail.id;
                               break;
                            }
                        }
                        this.productText=value;
                    }
                },
                //表单提交回调函数
                 onSubmit(){
                    // 请求路径
                    let url = "/manager/band/broadBand/broadBand-insert";
                    // 发送请求
                    this.$axios.post(url, this.optObj).then(resp => {
                        if (resp.data.status == this.STATUS_SUCCESS){
                            Dialog.alert({
                                message: '预约成功，请保持电话畅通等待工作人员联系。',
                            }).then(() => {
                            });
                            this.orderCount();
                            this.productView =false;
                            Object.keys(this.optObj).forEach(key =>{
                                if(key == 'optId'){
                                    this.optObj[key] = 3
                                }else {
                                    this.optObj[key] = ''
                                }
                            })
                            this.selectAddress = "";
                            this.productText="";
                            
                        }else if (resp.data.status ==this.FAIL){
                            Dialog.alert({
                                message: '已预约成功，请勿重复预约。',
                            }).then(() => {
                            });
                        }

                    });

                 },
                //根据归属市查询套餐信息
                 selectCityProduct (city) {
                    this.types = city;
                     // 请求路径
                     let url = "/manager/band/broadBand/broadBand-product-select-city";
                     var broadBandProduct = {city: city, optId: 3}
                     // 发送请求
                     this.$axios.post(url, broadBandProduct).then(resp => {
                         if (resp.data.status == this.STATUS_SUCCESS) {
                             this.productParticulars = resp.data.list;
                             this.$forceUpdate();
                         }
                     });
                 },
                

                //查询总下单人数
                orderCount(){
                    // 请求路径
                    let url = "/manager/band/broadBand/broadBand-count";
                    // 发送请求
                    this.$axios.post(url).then(resp => {
                        this.count = resp.data.data
                    });
                },
                // 验证身份证号码的合法性
                idCardValidator(val){
                    let r = isIdcard(val);
                    if(!r){
                        return "身份证号码不合法，请填写正确的身份证号码";
                    }
                },
                //验证手机号码正确性
                phoneValidator(val){
                    if(!/^1[3456789]\d{9}$/.test(val)){
                        return "请填写正确的联系电话";
                    }
                },
/*
                //查询产品套餐
                selectProduct(city){
                    // 请求路径
                    let url = "/manager/band/broadBand/broadBand-product-select";
                    var broadBandProduct = {city:city,optId:3}
                    // 发送请求
                    this.$axios.post(url,broadBandProduct).then(resp => {
                        if (resp.data.status == this.STATUS_SUCCESS){
                            let list = resp.data.list;
                            let arr = [];
                            for (let i=0;i<list.length;i++){
                                arr[i] = list[i].name;
                            }
                            // console.log(city)
                            if (city=="杭州市"){
                                this.hzColumns  = arr;
                                this.hzProductList = list;

                                // console.log( this.hzColumns)

                            }else {
                                this.columns = arr;
                                this.productList = list;

                                // console.log(this.columns)
                            }
                            
                        }
                    
                    });
                },*/
            },
        
            mounted() {
                this.selectCityProduct(this.types)

                this.orderCount();
                this.optObj.agentId =  this.$route.params.source
                
            }
                

    }
    
    
    
</script>

<style scoped>

  @keyframes freedraw {
    from {font-size: 0.28rem}
    to {font-size: 0.38rem}
  }

  .changeP{
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 7.5rem;
    font-size: 0.28rem;
    text-align: center;
    background: #ff9900;
    color: #ffffff;
    height: 0.45rem;
    line-height: 0.45rem;
    padding: 0.1rem 0rem;
    border-radius: 0.45rem;
    animation-name: freedraw;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .changeP a{
    color: #FFFFFF;
    display: inline-block;
    width: 100%;
  }
    .van-image {
        vertical-align: bottom;
        display: block;
    }
    

    h3{
        display: block;
        width: 2rem;
        height: 0.4rem;
        margin: 0.1rem auto 0.2rem 0.2rem;
        font-size: 0.28rem;
        background:#6699FF;
        color: white;
        text-align:center;
        border-radius:0.1rem ;
    }
    .introduce{
        width: 6.8rem;
        height: auto;
        padding: 0 0.3rem;
        margin-bottom: 0.55rem;
        font-size: 0.16rem;
        line-height: 0.4rem;
    }

 
  .phone_number_art{
      width: 7.3rem;
      background-color: #6699FF;
      border-top: 0.01rem solid #ffffff;
      padding: 0.1rem;
      font-size: 0.3rem;
      
  }
  .phone_number_div{
      width: 7.0rem;
      margin: 0rem auto;
      border-radius: 0.1rem;
      background-color: #ffffff;
      padding: 0.1rem;
  }


  .selecteds{
      color: #ffffff;
      background-color: #6699FF;
  }
 

  .phone_number span{
      display: flex;
      justify-content: center;

  }
 
  .phone_number span{
      display: flex;
      justify-content: center;
  }
  
  .span_city {
      color:#464547;
      margin-right: 0.2rem;
  }  
  .span_city span{
      display: block;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      padding-top: 0.04rem;
      padding-bottom: 0.04rem;
      border: 0.01rem solid #6699FF;
      border-radius: 0.5rem;
      margin: 0.06rem 0.06rem;
      height: 0.5rem;
      line-height: 0.5rem;
      min-width: 0.7rem;
      text-align: center;
  }
  
  .menu_detail_div{
      width: 6.8rem;
      margin: 0rem auto;
      border-radius: 0.1rem;
      background-color: #ffffff;
      padding: 0.1rem;
  }

  .menu_detail_div span{
      color: #333333;
      font-size: 0.2rem;
  }  
  .label_div{
      width: 6.8rem;
      color: #464547;
      text-align: center;
      font-size: 12px;
  }
  
  .label_div td{
      width: 1.7rem;
      height: 1.2rem;
  }

  .table{
      border-collapse: collapse;
      border: 0px solid #999;
  }
  .table td {
      border-top: 0;
      border-bottom: 1px solid #6699FF;
      border-left: 0;
  }
  .table tr.lastrow td {
      border-bottom: 0;
  }
  .table tr td.lastCol {
      border-right: 0;
  }
  .phone_number_query{
      display: flex;
      flex-wrap: wrap;
  }
  .phone_number_query span{
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      border: 0.01rem solid #6699FF;
      border-radius: 0.5rem;
      margin: 0.05rem 0.08rem;
      height: 0.5rem;
      line-height: 0.5rem;
      min-width: 0.7rem;
      text-align: center;
  }
</style> 

